import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

// Material Icons
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

export default function TableTitle({ ...props }) {
  const { text, action, isAction } = props;

  return (
    <Grid container spacing={1} sx={{ mb: 1 }}>
      <Grid item xs={isAction ? 6 : 12} md={isAction ? 6 : 12}>
        <Typography variant="h4">{text}</Typography>
      </Grid>
      {isAction ? (
        <Grid
          item
          xs={6}
          md={6}
          sx={{ textAlign: "right", alignContent: "center" }}
        >
          <Button
            component={Link}
            variant="text"
            to={action}
            sx={{ fontWeight: "600" }}
            startIcon={<AddCircleOutlineOutlinedIcon />}
          >
            Add Data
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
}
