import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

// Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Components
import AppBar from "../../components/AppBar";
import BreadcrumbsTitle from "../../components/title/Breadcrumbs";

// Phone Input
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useAuth } from "../../hooks/auth";

export default function Profile() {
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  const { logout, APIClient } = useAuth();
  const [data, setData] = React.useState({
    name: "Loading...",
    email: "Loading...",
    phone: "Loading...",
    role: "Loading...",
    institution: {
      name: "Loading..."
    },
  });

  React.useEffect(() => {
      APIClient.get("/admin")
      .then((res) => {
        console.log(res.data);
        
        setData(res.data)
    })
  }, [APIClient, logout]); // Empty dependency array ensures data is fetched only once


  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar title="Profile" />
      <Box
        component="main"
        sx={{
          backgroundColor: "fafafa",
          flexGrow: 1,
          overflow: "auto",
          paddingBottom: 10,
          height: "calc(100vh - 3px);",
          boxSizing: "border-box",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <BreadcrumbsTitle text="Profile" />
          <Card
            sx={{
              boxShadow:
                "rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;",
              p: 4,
              borderRadius: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ mb: 1 }}>
                <Typography variant="h4" align="left">
                  Personal Information
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Fullname
                </Typography>
                <TextField
                  required
                  fullWidth
                  disabled
                  value={data.name}
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Email Address
                </Typography>
                <TextField
                  required
                  fullWidth
                  disabled
                  value={data.email}
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Phone Number
                </Typography>
                <PhoneInput
                  country={"id"}
                  disabled
                  value={data.phone}
                  inputStyle={{
                    height: "48px",
                    width: "100%",
                    paddingLeft: "45px",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Institution
                </Typography>
                <TextField
                  required
                  fullWidth
                  disabled
                  value={data.institution ? data.institution.name ?? "N/A" : "N/A"}
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ mt: 3 }}>
                <Typography variant="h4" align="left">
                  Security Settings
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Password
                </Typography>
                <OutlinedInput
                  required
                  fullWidth
                  type={showPassword ? "password" : "text"}
                  placeholder="Input Your Password"
                  inputProps={{
                    style: { fontWeight: 400, fontSize: "14px" },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Confirm Password
                </Typography>
                <OutlinedInput
                  required
                  fullWidth
                  type={showConfirmPassword ? "password" : "text"}
                  placeholder="Input Your Confirm Password"
                  name="password"
                  inputProps={{
                    style: { fontWeight: 400, fontSize: "14px" },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    color: "#ffffff",
                    boxShadow: "none",
                    fontWeight: "600",
                  }}
                  type="submit"
                >
                  Update Information
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Container>
      </Box>
    </Box>
  );
}
