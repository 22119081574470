import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

// Components
import { Checkbox, FormControl, FormControlLabel, InputAdornment, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppBar from "../../../components/AppBar";
import BreadcrumbsTitle from "../../../components/title/Breadcrumbs";
import { useAuth } from "../../../hooks/auth";

const cat = {
  KOPI: "https://tkn-web.sgp1.cdn.digitaloceanspaces.com/coffee.png",
  BERAS:"https://tkn-web.sgp1.cdn.digitaloceanspaces.com/grains.png",
  MINYAK: "https://tkn-web.sgp1.cdn.digitaloceanspaces.com/oil.png",
  KEDELAI: "https://tkn-web.sgp1.cdn.digitaloceanspaces.com/soy.png",
  GULA: "https://tkn-web.sgp1.cdn.digitaloceanspaces.com/sugar.png",
}

export default function AddResi() {
  const { APIClient } = useAuth();
  const navigate = useNavigate();
  const [newInstitute, setNewInst] = useState(false);

  // Get Institutions
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
      APIClient.get("/institution")
      .then((res) => {
        setData(res.data)
    })
      .catch((err) => alert(err))
  }, [APIClient]);

  //Form Handling
  const formDefault = { name: "", instName: "", instID: "", category: "", imageUrl: "", price: 0, ypa: 0 };
  const [formData, setFormData] = useState(formDefault);
  const handleChange = (event) => {
    if (event.target.name === "category") {
      return setFormData({...formData, [event.target.name]: event.target.value, imageUrl: cat[event.target.value]});
    };
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const formFinal = newInstitute === true ? {
      ...formData,
      instID : "",
    } : {
      ...formData,
      instName: "",
    };
    APIClient.post("/admin/addresi", formFinal)
    .then((res) => {
      console.log(res);
      navigate("/resi-list")
    })
    .catch((err) => {
      alert(err)
    })
    console.log("Sending");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar title="Add Admin" />
      <Box
        component="main"
        sx={{
          backgroundColor: "fafafa",
          flexGrow: 1,
          overflow: "auto",
          paddingBottom: 10,
          height: "calc(100vh - 3px);",
          boxSizing: "border-box",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <BreadcrumbsTitle text="Add Resi" />
          <form onSubmit={handleSubmit}>
          <Card
            sx={{
              boxShadow:
                "rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;",
              p: 4,
              borderRadius: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ mb: 1 }}>
                <Typography variant="h4" align="left">
                  General Information
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Name
                </Typography>
                <TextField
                  required
                  fullWidth
                  placeholder="Input Resi's name"
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                  name="name"
                  onChange={handleChange}
                  value={formData.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Category
                </Typography>
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        label={null}
                        value={formData.category}
                        onChange={handleChange}
                        name="category"
                        style={{ height: "50px" }}
                      >
                        <MenuItem value="">
                          <em>Select Category</em>
                        </MenuItem>
                        {Object.keys(cat).map((v) => 
                        <MenuItem value={v}>{v}</MenuItem>)}
                      </Select>
                    </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Image URL
                </Typography>
                <TextField
                  required
                  disabled
                  fullWidth
                  placeholder="Select Category"
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                  name="imageUrl"
                  onChange={handleChange}
                  value={formData.imageUrl}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Institution
                </Typography>
                <FormControlLabel control={<Checkbox checked={newInstitute} onChange={() => setNewInst(!newInstitute)}/>} label="Institusi Baru?" />

                  {newInstitute ?        
                      <TextField
                      required
                      fullWidth
                      name="instName"
                      value={formData.instName}
                      onChange={handleChange}
                      inputProps={{
                        style: {
                          fontWeight: 400,
                          fontSize: "14px",
                          height: "15px",
                        },
                      }}
                    /> :
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        label={null}
                        value={formData.instID}
                        onChange={handleChange}
                        name="instID"
                        style={{ height: "50px" }}
                      >
                        <MenuItem value="">
                          <em>Select Institution</em>
                        </MenuItem>
                        {data.map((v) => 
                        <MenuItem value={v.id}>{v.name}</MenuItem>)}
                      </Select>
                    </FormControl>
                  }
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Price per Lot
                </Typography>
                <OutlinedInput
                  required
                  fullWidth
                  placeholder="Input Price per Lot"
                  type="number"
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                    },
                  }}
                  name="price"
                  onChange={handleChange}
                  value={formData.price}
                  startAdornment={
                    <InputAdornment position="start">
                      Rp.
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Yield Per Annum
                </Typography>
                <OutlinedInput
                  required
                  fullWidth
                  placeholder="Input Yield Per Annum"
                  type="number"
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                    },
                  }}
                  name="ypa"
                  onChange={handleChange}
                  value={formData.ypa}
                  endAdornment={
                    <InputAdornment position="end">
                      %
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    color: "#ffffff",
                    boxShadow: "none",
                    fontWeight: "600",
                  }}
                  type="submit"
                >
                  Add New Resi
                </Button>
              </Grid>
            </Grid>
          </Card>
          </form>
        </Container>
      </Box>
    </Box>
  );
}
