import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";

export default function CountsCard({ ...props }) {
  const { title, subtitle, total, avatar } = props;

  return (
    <Card
      sx={{
        boxShadow:
          "rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;",
        borderRadius: 2,
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={8} md={8}>
            <Typography variant="h3" color="text.secondary">
              {title}
            </Typography>
            <Typography variant="h1" gutterBottom>
              {total}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {subtitle}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            md={4}
            sx={{ textAlign: "-webkit-right", alignContent: "center" }}
          >
            <Avatar
              alt="Anon"
              sx={{ width: 45, height: 45, backgroundColor: "#FAFAFA" }}
            >
              {avatar}
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
