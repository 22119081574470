import MuiAppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { Fragment, useState } from "react";

// Material Icons
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import MenuIcon from "@mui/icons-material/Menu";

// Data List
import { ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { NavLink } from "react-router-dom";
import {
  itemList
} from "../components/listItems";
import { useAuth } from "../hooks/auth";

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function BasedLayout({ ...props }) {
  const { title } = props;
  const { logout } = useAuth();
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <CssBaseline />
      <AppBar position="absolute" open={open} sx={{ boxShadow: "none" }}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h2" noWrap sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          
          <IconButton onClick={logout} color="inherit">
            <ExitToAppOutlinedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} sx={{ height: "100vh" }}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          {
            itemList.map((v, i) => (
              <div key={i}>
                <Fragment>
                  <ListSubheader component="div" inset>
                    <Typography
                      color={"primary"}
                      variant="body2"
                      fontWeight={"600"}
                      sx={{ mt: 2, mb: 1 }}
                    >
                      {v.subheader}
                    </Typography>
                  </ListSubheader>
                  {
                    v.list.map((v,i) => (
                      <ListItemButton
                        component={NavLink}
                        to={v[0]}
                        sx={{
                          "&.active": {
                            backgroundColor: "#faf4c7",
                            borderRight: "4px solid #e3ad1a",
                          },
                        }}
                        key={i}
                      >
                        <ListItemIcon>
                          {v[2]}
                        </ListItemIcon>
                        <ListItemText
                          primary={<Typography variant="body2">
                            {v[1]}
                          </Typography>}
                          />
                      </ListItemButton>
                    ))
                  }
                </Fragment>
                <Divider sx={{ my: 1 }} />
              </div>
            ))
          }
        </List>
      </Drawer>
    </Fragment>
  );
}
