import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { MonetizationOn } from "@mui/icons-material";

// Generate Order Data
function createData(id, fullName, account, lot, date) {
  return { id, fullName, account, lot, date };
}

function daysUntil(date) {
  // Get today's date
  const today = new Date();

  // Check if the provided date is invalid
  if (isNaN(date.getTime())) {
    return null; // Return null for invalid dates
  }

  // Get the difference in milliseconds
  const timeDifference = date.getTime() - today.getTime();

  // Convert milliseconds to days and round down to whole days
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return days;
}

const rows = [
  createData(
    0,
    "Aga Subagja",
    "BCA 3203012503770011",
    "58 Lot",
    new Date("01-08-2025"),
  ),
  createData(
    0,
    "Oksidian Tafly",
    "Mandiri 3203012503770011",
    "32 Lot",
    new Date("01-08-2026"),
  ),
  createData(
    0,
    "Ginung",
    "BRI 3203012503770011",
    "1258 Lot",
    new Date("01-07-2025"),
  ),
];

export default function BuybackTable({data}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "800" }}>No</TableCell>
              <TableCell sx={{ fontWeight: "800" }}>Nama Lengkap</TableCell>
              <TableCell sx={{ fontWeight: "800" }}>Rekening</TableCell>
              <TableCell sx={{ fontWeight: "800" }}>Total Buyback</TableCell>
              <TableCell sx={{ fontWeight: "800" }}>Total Yield</TableCell>
              <TableCell sx={{ fontWeight: "800" }}>Nama Resi</TableCell>
              <TableCell sx={{ fontWeight: "800" }}>Waktu Buyback</TableCell>
              <TableCell sx={{ fontWeight: "800" }} align="right">
                Aksi
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row, i) => (
              <TableRow key={i + 1}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{row.user.name}</TableCell>
                <TableCell>{"N/A"}</TableCell>
                <TableCell>{Number(row.sum.totalPrice).toLocaleString()}</TableCell>
                <TableCell>{Number(row.sum.totalYield).toLocaleString()}</TableCell>
                <TableCell>{row.nft.resi.name}</TableCell>
                <TableCell>{new Date(row.nft.endDate).toLocaleDateString() + ", " + (daysUntil(new Date(row.nft.endDate))) + " hari lagi"}</TableCell>
                <TableCell align="right">
              <MonetizationOn
                sx={{ width: 15, height: 15 }}
                color="primary"
              /></TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
