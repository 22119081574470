import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

// Components
import Footer from "./components/Footer";

// Auth - Pages
import SignIn from "./pages/auth/SignIn";

// Pages
import Dashboard from "./pages/Dashboard";

// Pages - Transactions
import TransactionListIndex from "./pages/lists/history/Index";
import TransactionListView from "./pages/lists/history/View";
import PurchasedListindex from "./pages/lists/purchased/Index";
import UserListIndex from "./pages/lists/user/Index";
import UserListView from "./pages/lists/user/View";
import UserListAdd from "./pages/lists/user/Add";

// Pages - Account
import Profile from "./pages/account/Profile";
import AdminListAdd from "./pages/account/admin/Add";
import AdminListIndex from "./pages/account/admin/Index";
import AdminListView from "./pages/account/admin/View";

// Pages - Other
import { ProtectedRoute } from "./components/ProtectedRoute";
import ContactList from "./pages/others/Contact";

import { AuthProvider } from "./hooks/auth";
import ResiList from "./pages/lists/resi/Index";
import ViewResiDetail from "./pages/lists/resi/View";
import AddResi from "./pages/lists/resi/Add";
import { LocalizationProvider } from "@mui/x-date-pickers";

const theme = createTheme({
  palette: {
    background: {
      default: "#FAFAFA",
    },
    primary: {
      main: "#63B4AB",
    },
  },
  typography: {
    letterSpacing: "-0.5px",
    h1: {
      fontFamily: `"Plus Jakarta Sans", sans-serif`,
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "34px",
    },
    h2: {
      fontFamily: `"Barlow", sans-serif`,
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "36px",
    },
    h3: {
      fontFamily: `"Plus Jakarta Sans", sans-serif`,
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "22px",
    },
    h4: {
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "28px",
    },
    body1: {
      fontFamily: `"Plus Jakarta Sans", sans-serif`,
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "22px",
    },
    body2: {
      fontFamily: `"Barlow", sans-serif`,
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "22px",
    },
    caption: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
    },
    button: {
      textTransform: "none",
    },
  },
});

const protectedRoute = (element) => <ProtectedRoute>{element}</ProtectedRoute>

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<SignIn />} exact></Route>
            {/* Transactions */}
            <Route path="/dashboard" element={protectedRoute(<Dashboard />)}></Route>
            <Route path="/user-list" element={protectedRoute(<UserListIndex />)}></Route>
            <Route path="/user-list/view" element={protectedRoute(<UserListView />)}></Route>
            <Route path="/user-list/add" element={protectedRoute(<UserListAdd />)}></Route>
            <Route
              path="/transaction-list"
              element={protectedRoute(<TransactionListIndex />)}
            ></Route>
            <Route
              path="/transaction-list/view"
              element={protectedRoute(<TransactionListView />)}
            ></Route>
            <Route
              path="/purchased-list"
              element={protectedRoute(<PurchasedListindex />)}
            ></Route>
            <Route
              path="/resi-list"
              element={protectedRoute(<ResiList />)}
            ></Route>
            <Route
              path="/resi-list/view"
              element={protectedRoute(<ViewResiDetail />)}
            ></Route>
            <Route
              path="/resi-list/add"
              element={protectedRoute(<AddResi />)}
            ></Route>
            {/* Accounts */}
            <Route path="/profile" element={protectedRoute(<Profile />)}></Route>
            <Route path="/admin-list" element={protectedRoute(<AdminListIndex />)}></Route>
            <Route path="/admin-list/add" element={protectedRoute(<AdminListAdd />)}></Route>
            <Route path="/admin-list/view" element={protectedRoute(<AdminListView />)}></Route>
            {/* Others */}
            <Route path="/contact-list" element={protectedRoute(<ContactList />)}></Route>
          </Routes>
          </AuthProvider>
        </Router>
        <Footer />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
