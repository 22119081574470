import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

const breadcrumbs = {
  // Others
  "Contact List": [["/dashboard", "Dashboard"], ["#", "Other"], ["#", "Contact List"]],

  // Account
  "Profile": [["/dashboard", "Dashboard"], ["#", "Account"]],

  "Admin List": [["/dashboard", "Dashboard"], ["#", "Admins"]],

  "Add Admin": [["/dashboard", "Dashboard"],
  ["/admin-list", "Admins"], 
  ["#", "Add Admin"]],

  "View Admin Detail": [["/dashboard", "Dashboard"], ["#", "Account"], 
  ["/admin-list", "Admin List"], ["#", "View Admin Detail"]],

  "User List": [["/dashboard", "Dashboard"], ["#", "Account"], 
  ["#", "User List"]],

  "View User Detail": [["/dashboard", "Dashboard"], ["#", "Account"], 
  ["/user-list", "User List"], ["#", "View User Detail"]],

  "Add User": [["/dashboard", "Dashboard"], ["#", "Account"], 
  ["/user-list", "User List"], ["#", "Add User"]],

  // Transactions
  "Penjualan TKN": [["/dashboard", "Dashboard"], ["#", "Transactions"]],

  "View Transaction Detail": [["/dashboard", "Dashboard"], ["/transaction-list", "Transactions"], 
  ["#", "View Transaksi Detail"]],

  "List Buyback": [["/dashboard", "Dashboard"], ["#", "Transactions"], 
  ["#", "List Buyback"]],

  "List Resi": [["/dashboard", "Dashboard"], ["#", "Resi"]],
  "Add Resi": [["/dashboard", "Dashboard"], ["/resi-list", "Resi"], ["#", "Add Resi"]],
  "View Resi Detail": [["/dashboard", "Dashboard"], ["/resi-list", "Resi"], ["#", "View Resi"]],
}

const filteredBreadcrumbs = (type) => {
  if (breadcrumbs[type]) {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumbs[type].map((v, i) => (
          <Typography
          component={Link}
          underline="hover"
          color="inherit"
          variant="body2"
          href={v[0]}
          key={i}
          >
            {v[1]}
          </Typography>
        ))}
      </Breadcrumbs>
    )
  } else {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Typography
          component={Link}
          underline="hover"
          color="inherit"
          variant="body2"
          href="/dashboard"
        >
          Dashboard
        </Typography>
      </Breadcrumbs>
    );
  }
};

export default function BreadcrumbsTitle({ ...props }) {
  const { text } = props;

  return (
    <Grid container spacing={1} sx={{ mb: 3, mt: 1 }}>
      <Grid item xs={12} md={12}>
        {filteredBreadcrumbs(text)}
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="h2" align="left">
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
}
