import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

// Phone Input
import "react-phone-input-2/lib/style.css";

// Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Components
import { Checkbox, FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppBar from "../../../components/AppBar";
import BreadcrumbsTitle from "../../../components/title/Breadcrumbs";
import { useAuth } from "../../../hooks/auth";

const roles = {
  admin: "ADMIN",
  superadmin: "SUPERADMIN"
}

export default function AddAdmin() {
  const { APIClient } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(true);
  const [newInstitute, setNewInst] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  // Get Institutions
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
      APIClient.get("/institution")
      .then((res) => {
        setData(res.data)
    })
      .catch((err) => alert(err))
  }, [APIClient]);

  //Form Handling
  const formDefault = { password: "", email: "", phone: "", name: "", role: "", instName: "", instID: "" };
  const [formData, setFormData] = useState(formDefault);
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const formFinal = newInstitute === true ? {
      ...formData,
      instID : "",
    } : {
      ...formData,
      instName: "",
    };
    APIClient.post("/admin/addadmin", formFinal)
    .then((res) => {
      console.log(res);
      navigate("/admin-list")
    })
    .catch((err) => {
      console.error(err)
    })
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar title="Add Admin" />
      <Box
        component="main"
        sx={{
          backgroundColor: "fafafa",
          flexGrow: 1,
          overflow: "auto",
          paddingBottom: 10,
          height: "calc(100vh - 3px);",
          boxSizing: "border-box",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <BreadcrumbsTitle text="Add Admin" />
          <form onSubmit={handleSubmit}>
          <Card
            sx={{
              boxShadow:
                "rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;",
              p: 4,
              borderRadius: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ mb: 1 }}>
                <Typography variant="h4" align="left">
                  Personal Information
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Fullname
                </Typography>
                <TextField
                  required
                  fullWidth
                  placeholder="Input Your Fullname"
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                  name="name"
                  onChange={handleChange}
                  value={formData.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Email Address
                </Typography>
                <TextField
                  required
                  fullWidth
                  placeholder="Input Your Email"
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                  name="email"
                  onChange={handleChange}
                  value={formData.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Phone Number
                </Typography>
                <TextField
                  required
                  fullWidth
                  placeholder="Input Your Phone Number"
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                  name="phone"
                  onChange={handleChange}
                  value={formData.phone}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Role
                </Typography>
                
                <FormControl fullWidth>
                      <Select
                        fullWidth
                        label={null}
                        value={formData.role}
                        onChange={handleChange}
                        style={{ height: "50px" }}
                        name="role"
                      >
                        <MenuItem value="">
                          <em>Select Role</em>
                        </MenuItem>
                        <MenuItem value={roles.admin}>Admin</MenuItem>
                        <MenuItem value={roles.superadmin}>Super Admin</MenuItem>
                      </Select>
                    </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Institution
                </Typography>
                <FormControlLabel control={<Checkbox checked={newInstitute} onChange={() => setNewInst(!newInstitute)}/>} label="Institusi Baru?" />

                  {newInstitute ?        
                      <TextField
                      required
                      fullWidth
                      name="instName"
                      value={formData.instName}
                      onChange={handleChange}
                      inputProps={{
                        style: {
                          fontWeight: 400,
                          fontSize: "14px",
                          height: "15px",
                        },
                      }}
                    /> :
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        label={null}
                        value={formData.instID}
                        onChange={handleChange}
                        name="instID"
                        style={{ height: "50px" }}
                      >
                        <MenuItem value="">
                          <em>Select Institution</em>
                        </MenuItem>
                        {data.map((v) => 
                        <MenuItem value={v.id}>{v.name}</MenuItem>)}
                      </Select>
                    </FormControl>
                  }
              </Grid>
              <Grid item xs={12} md={12} sx={{ mt: 3 }}>
                <Typography variant="h4" align="left">
                  Security Settings
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Password
                </Typography>
                <OutlinedInput
                  required
                  fullWidth
                  type={showPassword ? "password" : "text"}
                  placeholder="Input Your Password"
                  inputProps={{
                    style: { fontWeight: 400, fontSize: "14px" },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Confirm Password
                </Typography>
                <OutlinedInput
                  required
                  fullWidth
                  type={showConfirmPassword ? "password" : "text"}
                  placeholder="Input Your Confirm Password"
                  name="password"
                  onChange={handleChange}
                  inputProps={{
                    style: { fontWeight: 400, fontSize: "14px" },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    color: "#ffffff",
                    boxShadow: "none",
                    fontWeight: "600",
                  }}
                  type="submit"
                >
                  Add New Administrator
                </Button>
              </Grid>
            </Grid>
          </Card>
          </form>
        </Container>
      </Box>
    </Box>
  );
}
