import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";

// Axios

// Components
import AppBar from "../../components/AppBar";
import ContactCard from "../../components/cards/Contact";
import BreadcrumbsTitle from "../../components/title/Breadcrumbs";
import { useAuth } from "../../hooks/auth";

export default function Contact() {
  const { APIClient } = useAuth();
  let isRendered = useRef(false);
  const [loadData, setLoadData] = useState(true);
  const [contactsList, setContactsList] = useState([]);

  useEffect(() => {
    APIClient.get("/contactus", {
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiYXBpIiwiaWF0IjoxNTE2MjM5MDIyfQ.-pzAn3bcj0qxh134A5GPWCIdkXIBeYZfHP7r14_x9HQ`,
      },
    })
      .then((res) => {
        setContactsList(res.data);
        setLoadData(false);
      })
      .catch(() => {
        setLoadData(false);
        setContactsList([]);
      });
    return () => {
      isRendered.current = true;
    };
  }, [APIClient]);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar title="Contact List" />
      <Box
        component="main"
        sx={{
          backgroundColor: "fafafa",
          flexGrow: 1,
          overflow: "auto",
          paddingBottom: 10,
          height: "calc(100vh - 3px);",
          boxSizing: "border-box",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <BreadcrumbsTitle text="Contact List" />
          <Grid container spacing={2}>
            {loadData ? (
              <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
                <CircularProgress color="inherit" />
              </Grid>
            ) : (
              <Grid item xs={12} md={12}>
                {contactsList ? (
                  <ContactCard data={contactsList} />
                ) : (
                  <Typography variant="body1" fontWeight="600">
                    No contact data yet!
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
