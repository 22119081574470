import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";

// Material Icons
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import SettingsAccessibilityOutlinedIcon from '@mui/icons-material/SettingsAccessibilityOutlined';

// Components
import AppBar from "../components/AppBar";
import ChartCard from "../components/cards/Chart";
import CountsCard from "../components/cards/Counts";
import BreadcrumbsTitle from "../components/title/Breadcrumbs";
import { useAuth } from "../hooks/auth";

export default function Dashboard() {
  const { APIClient } = useAuth();
  // Data Fetching
  const [data, setData] = React.useState({});
  // const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  React.useEffect(() => {
    // Get: Total Users, TKN sold, TKN minted
    if (!isError) {
      APIClient.get("/admin/stats")
      .then((res) => {
        setData(res.data)
        // setIsLoading(false)
    })
      .catch((err) => {
        setIsError(true)
        console.error(err);
    })
    }
  }, [APIClient, isError]);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar title="Dashboard" />
      <Box
        component="main"
        sx={{
          backgroundColor: "fafafa",
          flexGrow: 1,
          overflow: "auto",
          paddingBottom: 10,
          height: "calc(100vh - 3px);",
          boxSizing: "border-box",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <BreadcrumbsTitle text="Dashboard" />
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <CountsCard
                    avatar={
                      <SettingsAccessibilityOutlinedIcon
                        sx={{ width: 25, height: 25 }}
                        color="primary"
                      />
                    }
                    title={"User Terdaftar"}
                    total={data["totaluser"] ?? "Loading..."}
                    subtitle={"Total Pengguna TKN"}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CountsCard
                    avatar={
                      <ManageSearchOutlinedIcon
                        sx={{ width: 25, height: 25 }}
                        color="primary"
                      />
                    }
                    title={"TKN Terjual"}
                    total={(data["tknsold"] ? Number(data["tknsold"]).toLocaleString() : "...") + " LOT " +  
                      (data["tknsold"] && data["totaltkn"] ? `(${((Number(data["tknsold"]) / Number(data["totaltkn"])) * 100).toLocaleString()}%)` : "")}
                    subtitle={"Total Lot Terjual"}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CountsCard
                    avatar={
                      <InventoryOutlinedIcon
                        sx={{ width: 25, height: 25 }}
                        color="primary"
                      />
                    }
                    title={"Jumlah TKN"}
                    total={data["totaltkn"] ? Number(data["totaltkn"]).toLocaleString() : "Loading..."}
                    subtitle={"Total NFT TKN Diterbitkan"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ChartCard chartdata={data["graphdata"] ?? []} totalsold={data["tknsold"] ? Number(data["tknsold"]).toLocaleString() : "N/A"}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
