import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

// Assets
import tknLogo from "../../assets/TKN.svg";

// Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth } from "../../hooks/auth";

export default function SignIn() {
  const { login } = useAuth();
  const [showPassword, setShowPassword] = useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const formDefault = { password: "", email: "" };
  const [formData, setFormData] = useState(formDefault);
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Sending");
    login(formData);
  };

  return (
    <Container maxWidth="sm">
      <Paper sx={{ boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px" }}>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 4,
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ mb: 1 }}>
                <img src={tknLogo} alt="TKN Logo" />
              </Grid>
              <Grid item xs={12} md={12} sx={{ mb: 1 }}>
                <Typography
                  variant="h3"
                  align="left"
                  sx={{ letterSpacing: "-0.5px" }}
                >
                  Back Office | Sign In
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="body2" sx={{ mb: "8px", fontWeight: "600" }}>
                  Email Address*
                </Typography>
                <TextField
                  required
                  fullWidth
                  type="email"
                  placeholder="Input Your Email Address"
                  name="email"
                  inputProps={{
                    style: { fontWeight: 400, fontSize: "14px" },
                  }}
                  autoFocus
                  onChange={handleChange}
                  value={formData.email}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="body2" sx={{ mb: "8px", fontWeight: "600" }}>
                  Password*
                </Typography>
                <OutlinedInput
                  required
                  fullWidth
                  type={showPassword ? "password" : "text"}
                  placeholder="Input Your Password"
                  name="password"
                  onChange={handleChange}
                  value={formData.password}
                  inputProps={{
                    style: { fontWeight: 400, fontSize: "14px" },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, color: "#ffffff", boxShadow: "none" }}
              type="submit"
            >
              Sign In
            </Button>
          </form>
        </Box>
      </Paper>
    </Container>
  );
}
