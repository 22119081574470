import React from "react";

// Material Icons
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import SwitchAccountOutlinedIcon from "@mui/icons-material/SwitchAccountOutlined";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export const itemList = [
  {
    "subheader": "Transactions",
    "list": [
      ["/dashboard", "Dashboard", 
        <GridViewOutlinedIcon />
      ],
      ["/transaction-list", "Penjualan TKN",
        <ManageSearchOutlinedIcon />
      ],
      ["/purchased-list", "Minting TKN",
        <AttachMoneyIcon />
      ],
      ["/resi-list", "List Resi",
        <InventoryOutlinedIcon />
      ]
    ]
  },
  {
    "subheader": "Account",
    "list": [
      ["/profile", "Profile",
        <AccountBoxOutlinedIcon />
      ],
      ["/admin-list", "Admin List",
        <AdminPanelSettingsIcon />
      ],
      ["/user-list", "User List",
        <SwitchAccountOutlinedIcon />
      ],
    ]
  },
  {
    "subheader": "Others",
    "list": [
      ["/contact-list", "Contact List",
        <ContactPhoneOutlinedIcon />
      ]
    ]
  }
]
