import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";

// Components
import AppBar from "../../../components/AppBar";
import TransactionTable from "../../../components/tables/Transaction";
import BreadcrumbsTitle from "../../../components/title/Breadcrumbs";
import TableTitle from "../../../components/title/Table";

// Material Icons
import { MoneyOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import SecondCard from "../../../components/cards/SecondCard";
import { useAuth } from "../../../hooks/auth";

export default function TransactionHistoryList() {
  const { APIClient } = useAuth();
  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  React.useEffect(() => {
    if (!isError) {
      APIClient.get("/order")
      .then((res) => {
        setData(res.data)
        setTotal(res.data.reduce((total, v) => total + (v.nftAmount ?? 0),0))
        setTotalPrice(res.data.reduce((total, v) => total + (v.totalPrice ?? 0),0))
        setIsLoading(false)
    })
      .catch((err) => setIsError(true))
    }
  }, [APIClient, isError]); // Empty dependency array ensures data is fetched only once

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar title="Transaction History" />
      <Box
        component="main"
        sx={{
          backgroundColor: "fafafa",
          flexGrow: 1,
          overflow: "auto",
          paddingBottom: 10,
          height: "calc(100vh - 3px);",
          boxSizing: "border-box",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <BreadcrumbsTitle text="Penjualan TKN" />
          <SecondCard
            avatar={
              <MoneyOutlined
                sx={{ width: 25, height: 25 }}
                color="primary"
              />
            }
            total={total.toLocaleString() + " Lot"}
            subtitle={"Total Lot Terjual"}
            total2={"Rp " + totalPrice.toLocaleString()}
            subtitle2={"Total Lot Terjual (Rupiah)"}
          />
          <Card
            sx={{
              boxShadow:
                "rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;",
              p: 4,
              mt: 2,
              borderRadius: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TableTitle
                  text="List Transaksi"
                  action="#"
                  isAction={false}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                {isError ? "Error": null}
                {
                  isLoading ? <CircularProgress/> : 
                  <TransactionTable data={data} />
                }
              </Grid>
            </Grid>
          </Card>
        </Container>
      </Box>
    </Box>
  );
}
