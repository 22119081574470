import React, { useState } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Pagination from "@mui/material/Pagination";
import moment from "moment";

// Components
import CountsCard from "../cards/Counts";

// Material Icons
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";

const BasedLayoutCard = ({ ...props }) => {
  const { name, institutionName, message, date, email, phoneNumber } = props;

  const handleOpenEmail = (e, email) => {
    const url = "mailto:" + email;
    e.preventDefault();
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const handleOpenWhatsApp = (e, phoneNumber) => {
    const url = "https://api.whatsapp.com/send?phone=" + phoneNumber;
    e.preventDefault();
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Card
      sx={{
        boxShadow:
          "rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;",
        borderRadius: 2,
      }}
    >
      <CardContent>
        <Typography variant="caption" fontWeight="400" color="text.secondary">
          {moment(date).format("LLL")}
        </Typography>
        <Typography variant="body1" fontWeight="600" sx={{ mt: 1 }}>
          {name}
        </Typography>
        <Typography variant="caption" fontWeight="400" color="text.secondary">
          {institutionName}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontStyle: "italic", mt: 1 }}
        >
          {message}
        </Typography>
        <Divider sx={{ mb: 3, mt: 2 }} />
        <Stack direction="row" spacing={2}>
          <Button
            onClick={(e) => {
              handleOpenEmail(e, email);
            }}
            variant="outlined"
            size="small"
          >
            Email
          </Button>
          <Button
            onClick={(e) => {
              handleOpenWhatsApp(e, phoneNumber);
            }}
            variant="outlined"
            size="small"
          >
            Phone Number
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default function Contact({ ...props }) {
  const { data } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  // Current Page
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
  const pageCount = Math.ceil(data.length / postsPerPage);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <CountsCard
          avatar={
            <ContactPhoneOutlinedIcon
              sx={{ width: 25, height: 25 }}
              color="primary"
            />
          }
          title={"Submitted Contacts"}
          total={data.length}
        />
      </Grid>
      {currentPosts.map((x) => (
        <Grid item key={x.id} xs={12} md={4}>
          <BasedLayoutCard
            name={x.fullname}
            institutionName={x.institutionName}
            message={x.message}
            phoneNumber={x.phoneNumber}
            email={x.email}
            date={x.createdAt}
          />
        </Grid>
      ))}
      <Grid item xs={12} md={12} sx={{ mt: 2 }}>
        <Stack alignItems="center">
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handleChangePage}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}
