import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";

// Components
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { Button, CircularProgress, InputAdornment, Modal, OutlinedInput, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import AppBar from "../../../components/AppBar";
import NFTTable from "../../../components/tables/NFT";
import BreadcrumbsTitle from "../../../components/title/Breadcrumbs";
import { useAuth } from "../../../hooks/auth";

export default function ViewResiDetail() {
  const { APIClient } = useAuth();
  // Data Fetching
  const [searchParams] = useSearchParams();
  const [data, setData] = React.useState({
    NFT: [],
    name: "Loading...",
    category: "Loading...",
    image: "Loading...",
    institution: {
      name: "Loading..."
    },
    price: 0,
    yieldPerAnnum: 0,
  });
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  // Modal Handling
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if (!isLoading) {setOpen(true)};
  };
  const handleClose = () => setOpen(false);

  //Form Handling
  const [start, setStart] = React.useState(moment());
  const [end, setEnd] = React.useState(moment().add(1, 'd'));
  const [amount, setAmount] = React.useState(0);
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [priceError, setPError] = React.useState(false);

  const handleClick = () => {
    if (end == null) {
      return alert("Fill end date")
    }
    const senddata = {
      startDate: start.toISOString(),
      endDate: end.toISOString(),
      amount: Number(amount),
      resiId: searchParams.get("id")
    }
    APIClient.post("/resi/nft", senddata)
    .then(() => window.location.reload())
    .catch((err) => alert(err))
    console.log(data);
  }

  React.useEffect(() => {
    if (!isError) {
      APIClient.get("/resi?id=" + searchParams.get("id"))
      .then((res) => {
        setData(res.data)
        setIsLoading(false)
    })
      .catch((err) => {
        setIsError(true)
        console.error(err);
    })
    }
  }, [APIClient, isError, searchParams]);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar title="View Resi Detail" />
      <Box
        component="main"
        sx={{
          backgroundColor: "fafafa",
          flexGrow: 1,
          overflow: "auto",
          paddingBottom: 10,
          height: "calc(100vh - 3px);",
          boxSizing: "border-box",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <BreadcrumbsTitle text="View Resi Detail" />
          <Card
            sx={{
              boxShadow:
                "rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;",
              p: 4,
              my: 4,
              borderRadius: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Name
                </Typography>
                <TextField
                  required
                  fullWidth
                  disabled
                  value={data.name}
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Category
                </Typography>
                <TextField
                  required
                  fullWidth
                  disabled
                  value={data.category}
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Image URL
                </Typography>
                <TextField
                  required
                  fullWidth
                  disabled
                  value={data.image}
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Institution
                </Typography>
                <TextField
                  required
                  fullWidth
                  disabled
                  value={data.institution ? data.institution.name : "N/A"}
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  Price
                </Typography>
                <OutlinedInput
                  fullWidth
                  disabled
                  value={Number(data.price).toLocaleString()}
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      Rp.
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  sx={{ mb: "8px", fontWeight: "600" }}
                >
                  YPA
                </Typography>
                <OutlinedInput
                  fullWidth
                  disabled
                  value={data.yieldPerAnnum}
                  inputProps={{
                    style: {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "15px",
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      %
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          </Card>
          <Card
            sx={{
              boxShadow:
                "rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;",
              p: 4,
              my: 4,
              borderRadius: 2,
            }}
          >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Grid container spacing={1} sx={{ mb: 1 }}>
                <Grid item xs={6} md={6}>
                  <Typography variant="h4">Add Data</Typography>
                </Grid>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    sx={{ textAlign: "right", alignContent: "center" }}
                  >
                    <Button
                      variant="text"
                      disabled={isLoading || isError}
                      sx={{ fontWeight: "600" }}
                      startIcon={<AddCircleOutlineOutlined />}
                      onClick={handleOpen}
                    >
                      Add Data
                    </Button>
                  </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              {isError ? "Error": null}
              {
                isLoading ? <CircularProgress/> : 
                <NFTTable data={data.NFT ?? []} />
              }
            </Grid>
          </Grid>
          </Card>
        </Container>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Stack direction={"column"} gap={4}>
            <Typography variant="h4" align="left">
              Add Contract Info
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: "8px", fontWeight: "600" }}
            >
              <b style={{color: "green"}}>Mulai</b> masa kontrak
            </Typography>
            <DatePicker value={start} disablePast
              name="start"
              onChange={(v) => setStart(v)}/>
            <Typography
              variant="body2"
              sx={{ mb: "8px", fontWeight: "600" }}
            >
              <b style={{color: "red"}}>Selesai</b> masa kontrak
            </Typography>
            <DatePicker value={end} minDate={start}
              name="end"
              onChange={(v) => setEnd(v)}/>
            <Typography
              variant="body2"
              sx={{ mb: "8px", fontWeight: "600" }}
            >
              Jumlah Pembiayaan Total
            </Typography>
            <OutlinedInput
              required
              fullWidth
              placeholder="Input Total Price"
              type="number"
              inputProps={{
                style: {
                  fontWeight: 400,
                  fontSize: "14px",
                },
              }}
              name="price"
              error={priceError}
              onChange={(e) => {
                const totalprice = Number(e.target.value)
                const totalamount = totalprice / Number(data.price)
                const promises = [setTotalPrice(e.target.value), setAmount(totalamount.toString())]
                if (totalprice % Number(data.price) !== 0) {
                  promises.push(setPError(true))
                } else if (priceError) {
                  promises.push(setPError(false))
                }
                return Promise.all(promises)
              }}
              value={totalPrice}
              startAdornment={
                <InputAdornment position="start">
                  Rp.
                </InputAdornment>
              }
            />
            <Typography
              variant="body2"
              sx={{ mb: "8px", fontWeight: "600" }}
            >
              Jumlah Lot
            </Typography>
            <OutlinedInput
              required
              fullWidth
              placeholder="Input Total Lot"
              type="number"
              inputProps={{
                style: {
                  fontWeight: 400,
                  fontSize: "14px",
                },
              }}
              name="amount"
              error={priceError}
              onChange={(e) => {
                const totalamount = Number(e.target.value)
                const totalprice = totalamount * Number(data.price)
                console.log("totalprice: ", totalPrice, "totalamount: ", totalamount);
                const promises = [setAmount(e.target.value), setTotalPrice(totalprice.toString())]
                return Promise.all(promises)
              }}
              value={amount}
              endAdornment={
                <InputAdornment position="end">
                  Lot
                </InputAdornment>
              }
            />
            <Button
              fullWidth
              variant="contained"
              disabled={priceError}
              sx={{
                mt: 3,
                color: "#ffffff",
                boxShadow: "none",
                fontWeight: "600",
              }}
              onClick={handleClick}
            >
              {priceError ? "Data harga belum benar" : "Tambah NFT"}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
}
