import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import { Link } from "react-router-dom";

// Material Icon
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useAuth } from "../../hooks/auth";

export default function AdminTable({data}) {
  const { APIClient } = useAuth();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleDeleteUser = (id) => {
    APIClient.delete("/admin/edituser?id=" + id)
    .then((res) => {
      console.log(res)
      window.location.reload();
    })
    .catch((err) => alert(err))
  }

  const handleNotifOn = (id) => {
    APIClient.post("/admin/notification?id=" + id)
    .then((res) => {
      console.log(res)
      window.location.reload();
    })
    .catch((err) => alert(err))
  }

  const handleNotifOff = (id) => {
    APIClient.delete("/admin/notification?id=" + id)
    .then((res) => {
      console.log(res)
      window.location.reload();
    })
    .catch((err) => alert(err))
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "800" }}>No</TableCell>
              <TableCell sx={{ fontWeight: "800" }}>Fullname</TableCell>
              <TableCell sx={{ fontWeight: "800" }}>Email Address</TableCell>
              <TableCell sx={{ fontWeight: "800" }}>
                Phone Number
              </TableCell>
              <TableCell sx={{ fontWeight: "800" }}>
                Organization
              </TableCell>
              <TableCell sx={{ fontWeight: "800" }} align="right">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row, i) => (
              <TableRow key={row.id}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.institution == null ? "SUPERADMIN" : row.institution.name}</TableCell>
                <TableCell align="right">
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                  >
                    <IconButton component={Link} to={"/admin-list/view?id=" + row.id}>
                      <RemoveRedEyeOutlinedIcon />
                    </IconButton>
                    {
                      row.notification === "NONE" ? 
                      (<IconButton onClick={() => handleNotifOn(row.id)}>
                          <NotificationsActiveIcon />
                        </IconButton>) :
                        (
                        <IconButton>
                          <NotificationsOffIcon onClick={() => handleNotifOff(row.id)}/>
                        </IconButton>
                        )

                    }
                      {row.role !== "SUPERADMIN" ? ( //TODO: Based on role instead of institution
                      <IconButton onClick={() => handleDeleteUser(row.id)}>
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                      ) : null}
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
