import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { HTTPClient } from "../lib/api";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  const value = useMemo(
    () => {
      // call this function when you want to authenticate the user
  async function login(data) {
    HTTPClient.post("/admin/login", data)
      .then((res) => res.data)
      .then((res) => {
        setUser(res);
        navigate("/dashboard")
      })
      .catch((err) => {
        alert("Auth failed: " + err)
      })
  };

  // call this function to sign out logged in user
  function logout() {
    setUser(null);
    navigate("/", { replace: true });
  };

  const APIClient = {
    get: (url) => HTTPClient.get(url).catch((err) => {
      if (err.response.status === 401) {
        return logout()
      }
      throw err
    }),
    post: (url, body) => HTTPClient.post(url, body).catch((err) => {
      if (err.response.status === 401) {
        return logout()
      }
      throw err
    }),
    delete: (url) => HTTPClient.delete(url).catch((err) => {
      if (err.response.status === 401) {
        return logout()
      }
      throw err
    })
  }
      return {user, login, logout, APIClient}
    },
    [navigate, setUser, user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};