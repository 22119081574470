import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

// Phone Input
import "react-phone-input-2/lib/style.css";

// Icons

// Components
import { CloudUpload } from "@mui/icons-material";
import Papa from 'papaparse';
import { useNavigate } from "react-router-dom";
import AppBar from "../../../components/AppBar";
import BreadcrumbsTitle from "../../../components/title/Breadcrumbs";
import { useAuth } from "../../../hooks/auth";

export default function AddUser() {
  const { APIClient } = useAuth();
  const navigate = useNavigate()
  const [selectedFile, setSelectedFile] = useState(null);

  const handleChange = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();
  
    reader.onload = (event) => {
      const csvData = event.target.result;
      Papa.parse(csvData, {
        header: true,
        complete: (results) => {
          const jsonData = results.data; // Array of objects from CSV data
          const filtered = jsonData.filter((v) => (v.Nama && v.Email && v.Password && v.NoTelp))
          setSelectedFile(filtered);
          console.log('Converted JSON data:', filtered);
          // Handle the converted JSON data (e.g., display, store in state)
          // You can further process the data as needed
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
        },
      });
    };
  
    reader.readAsText(file);

  };

  const handleSend = () => {
    APIClient.post("/admin/batchadduser", selectedFile)
    .then((res) => {
      console.log(res);
      navigate("/user-list");
    })
    .catch((err) => alert("Error: " + err))
  }

  const handleDownload = () => {
    // No need to create a Blob object, use the external URL directly
    const link = document.createElement('a');
    link.href = "https://tkn-web.sgp1.cdn.digitaloceanspaces.com/example-user-admin.csv";
    link.download = 'example-users.csv'; // Set your desired filename
    link.click();
    URL.revokeObjectURL(link.href);
  }

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar title="Add User" />
      <Box
        component="main"
        sx={{
          backgroundColor: "fafafa",
          flexGrow: 1,
          overflow: "auto",
          paddingBottom: 10,
          height: "calc(100vh - 3px);",
          boxSizing: "border-box",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <BreadcrumbsTitle text="Add User" />
          <Card
            sx={{
              boxShadow:
                "rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;",
              p: 4,
              borderRadius: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sx={{ mb: 1 }}>
                <Typography variant="h4" align="left">
                  Personal Information
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} sx={{ mb: 1 }}>
                <Button onClick={handleDownload}>
                  Download contoh file .csv
                </Button>
              </Grid>
              <Grid item xs={12} md={12}>
              <Typography variant="body2" sx={{ mb: "8px", fontWeight: "600" }}>
                Upload File
              </Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUpload />}
              >
                Upload file
                <input hidden type="file" accept=".csv" onChange={handleChange} />
              </Button>
              {
                selectedFile ? 
                <Typography variant="body2" sx={{ mb: "8px", fontWeight: "600" }}>
                  File Uploaded
                </Typography> :
                null
              }
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    color: "#ffffff",
                    boxShadow: "none",
                    fontWeight: "600",
                  }}
                  onClick={handleSend}
                >
                  Add New Users
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Container>
      </Box>
    </Box>
  );
}
