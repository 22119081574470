import { ButtonGroup } from '@mui/material';
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

function generateRandomColor() {
  const hexChars = '0123456789abcdef';
  let color = '#';

  for (let i = 0; i < 6; i++) {
    color += hexChars[Math.floor(Math.random() * 16)];
  }

  return color;
}

export default function ChartCard({...props}) {
    const { chartdata, totalsold } = props;

    return (

    <Card
    sx={{
      boxShadow:
        "rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;",
      borderRadius: 2,
    }}
  >
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant='h3'>
                        Grafik Penjualan TKN
                    </Typography>
                    <Typography variant='body1' color={"#439C92"} mt={2}>
                        <b style={{fontSize: "40px"}}>{totalsold}</b> LOT
                    </Typography>
                    <Typography variant='body1'>
                        Volume (24h)
                    </Typography>
                </Grid>
                <Grid item xs={6} 
            sx={{ textAlign: "-webkit-right", alignContent: "center" }}>
                    <ButtonGroup>
                        <Button>1d</Button>
                        <Button>7d</Button>
                        <Button>1m</Button>
                        <Button>1y</Button>
                        <Button>YTD</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <Divider sx={{my: 4}}/>
            {chartdata && chartdata.length > 0 ?
            (
              <ResponsiveContainer width="100%" height={400}>
                <LineChart
                  width={500}
                  height={300}
                  data={chartdata}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis type="number" tickFormatter={(v, i) => (v)} label={{ value: 'Total LOT Terjual', angle: -90, position: 'insideLeft' }}/>
                  <Tooltip />
                  <Legend />
                  {
                    Object.keys(chartdata[0]).map((v, i) => v === "name" ? null : <Line key={i} dataKey={v} stroke={generateRandomColor()}/>)
                  }
                </LineChart>
              </ResponsiveContainer>
            )
            : "Data Not Available"}
        </CardContent>
    </Card>

    )
}