import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function Footer() {
  return (
    <Box
      sx={{
        width: "100vw",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        pb: 2,
        pt: 2,
        backgroundColor: "#ffffff",
        boxShadow: "unset",
      }}
    >
      <Typography variant="body2" align="center" color="#7e7e7e">
        All Rights Reserved ©{new Date().getFullYear()} -{" "}
        <span style={{ color: "#63B4AB", fontWeight: "600" }}>
          PT. Indonesia Blockchain Persada
        </span>
      </Typography>
    </Box>
  );
}
